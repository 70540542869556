<div *ngIf="(applicationSettingsService.showCookieNotice$ | async) && showCookieNotice" class="cookie-notice">
    <p [innerHTML]="'dynamische_frontend_texte.cookies.hinweis' | translate | safeStyle"></p>
    <p [innerHTML]="'dynamische_frontend_texte.cookies.akzeptieren' | translate | safeStyle"></p>
    <div>
        <button
            class="btn-success btn"
            title="Akzeptiere Cookie Richtlinie"
            (click)="acceptCookies()"
            [innerHTML]="'dynamische_frontend_texte.cookies.akzeptieren_button' | translate | safeStyle"
        ></button>
    </div>
</div>
