/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApplicationSettingsResponse } from '../models/application-settings-response';
import { getApiPublicApplicationSettings } from '../fn/application-settings/get-api-public-application-settings';
import { GetApiPublicApplicationSettings$Params } from '../fn/application-settings/get-api-public-application-settings';


/**
 * Application Settings
 */
@Injectable({ providedIn: 'root' })
export class ApplicationSettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getApiPublicApplicationSettings()` */
  static readonly GetApiPublicApplicationSettingsPath = '/api/public/{_locale}/application_settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicApplicationSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicApplicationSettings$Response(params: GetApiPublicApplicationSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationSettingsResponse>> {
    return getApiPublicApplicationSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicApplicationSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicApplicationSettings(params: GetApiPublicApplicationSettings$Params, context?: HttpContext): Observable<ApplicationSettingsResponse> {
    return this.getApiPublicApplicationSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationSettingsResponse>): ApplicationSettingsResponse => r.body)
    );
  }

}
