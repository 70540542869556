/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AgenciesResponse } from '../models/agencies-response';
import { getApiPublicAgencies } from '../fn/agencies/get-api-public-agencies';
import { GetApiPublicAgencies$Params } from '../fn/agencies/get-api-public-agencies';


/**
 * Agencies
 */
@Injectable({ providedIn: 'root' })
export class AgenciesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getApiPublicAgencies()` */
  static readonly GetApiPublicAgenciesPath = '/api/public/{_locale}/agencies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicAgencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicAgencies$Response(params: GetApiPublicAgencies$Params, context?: HttpContext): Observable<StrictHttpResponse<AgenciesResponse>> {
    return getApiPublicAgencies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicAgencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicAgencies(params: GetApiPublicAgencies$Params, context?: HttpContext): Observable<AgenciesResponse> {
    return this.getApiPublicAgencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgenciesResponse>): AgenciesResponse => r.body)
    );
  }

}
