/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getApiPublicAllServices } from '../fn/services/get-api-public-all-services';
import { GetApiPublicAllServices$Params } from '../fn/services/get-api-public-all-services';
import { getApiPublicServices } from '../fn/services/get-api-public-services';
import { GetApiPublicServices$Params } from '../fn/services/get-api-public-services';
import { ServicesResponse } from '../models/services-response';


/**
 * Services
 */
@Injectable({ providedIn: 'root' })
export class ServicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getApiPublicServices()` */
  static readonly GetApiPublicServicesPath = '/api/public/{_locale}/services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicServices$Response(params: GetApiPublicServices$Params, context?: HttpContext): Observable<StrictHttpResponse<ServicesResponse>> {
    return getApiPublicServices(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicServices(params: GetApiPublicServices$Params, context?: HttpContext): Observable<ServicesResponse> {
    return this.getApiPublicServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServicesResponse>): ServicesResponse => r.body)
    );
  }

  /** Path part for operation `getApiPublicAllServices()` */
  static readonly GetApiPublicAllServicesPath = '/api/public/{_locale}/all_services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicAllServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicAllServices$Response(params: GetApiPublicAllServices$Params, context?: HttpContext): Observable<StrictHttpResponse<ServicesResponse>> {
    return getApiPublicAllServices(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicAllServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicAllServices(params: GetApiPublicAllServices$Params, context?: HttpContext): Observable<ServicesResponse> {
    return this.getApiPublicAllServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServicesResponse>): ServicesResponse => r.body)
    );
  }

}
