/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getApiPublicI18NTranslation } from '../fn/i-18-n-translation/get-api-public-i-18-n-translation';
import { GetApiPublicI18NTranslation$Params } from '../fn/i-18-n-translation/get-api-public-i-18-n-translation';
import { I18NTranslationResponse } from '../models/i-18-n-translation-response';


/**
 * i18nTranslation
 */
@Injectable({ providedIn: 'root' })
export class I18NTranslationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getApiPublicI18NTranslation()` */
  static readonly GetApiPublicI18NTranslationPath = '/api/public/{_locale}/i18nTranslation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicI18NTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicI18NTranslation$Response(params: GetApiPublicI18NTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<I18NTranslationResponse>> {
    return getApiPublicI18NTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicI18NTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicI18NTranslation(params: GetApiPublicI18NTranslation$Params, context?: HttpContext): Observable<I18NTranslationResponse> {
    return this.getApiPublicI18NTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<I18NTranslationResponse>): I18NTranslationResponse => r.body)
    );
  }

}
