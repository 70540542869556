/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReservationResponse } from '../../models/reservation-response';

export interface PostApiPublicAppointmentReservation$Params {

/**
 * Agency ID
 */
  agency_id: string;

/**
 * Service ID
 */
  service_id: string;

/**
 * Choosen Date
 */
  date_choice: string;

/**
 * Choosen Time
 */
  time_choice: string;

/**
 * Lastname of Citizen.
 */
  strategy_name_last_name?: string;

/**
 * choosen list option
 */
  strategy_list_chosen_option?: string;

/**
 * File number of Citizen.
 */
  strategy_file_number_file_number?: string;

/**
 * Department Number of Citizen.
 */
  strategy_file_number_department_number?: string;

/**
 * Participants count
 */
  participants_count?: number;

/**
 * optional allready reserved uuid
 */
  reserved_uuid?: string;

/**
 * appointment type as online or onsite
 */
  service_type?: string;
  '_locale': 'de' | 'en' | 'fr';
}

export function postApiPublicAppointmentReservation(http: HttpClient, rootUrl: string, params: PostApiPublicAppointmentReservation$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationResponse>> {
  const rb = new RequestBuilder(rootUrl, postApiPublicAppointmentReservation.PATH, 'post');
  if (params) {
    rb.query('agency_id', params.agency_id, {});
    rb.query('service_id', params.service_id, {});
    rb.query('date_choice', params.date_choice, {});
    rb.query('time_choice', params.time_choice, {});
    rb.query('strategy_name_last_name', params.strategy_name_last_name, {});
    rb.query('strategy_list_chosen_option', params.strategy_list_chosen_option, {});
    rb.query('strategy_file_number_file_number', params.strategy_file_number_file_number, {});
    rb.query('strategy_file_number_department_number', params.strategy_file_number_department_number, {});
    rb.query('participants_count', params.participants_count, {});
    rb.query('reserved_uuid', params.reserved_uuid, {});
    rb.query('service_type', params.service_type, {});
    rb.path('_locale', params['_locale'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ReservationResponse>;
    })
  );
}

postApiPublicAppointmentReservation.PATH = '/api/public/{_locale}/appointments';
