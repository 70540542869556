import { Component, OnInit } from '@angular/core';
import { CookieService } from '../../services/cookie/cookie.service';
import { ApplicationSettingsService } from '../../services/application-settings/application-settings.service';

@Component({
    selector: 'otb-cookie-notice',
    templateUrl: './cookie-notice.component.html',
    styleUrls: ['./cookie-notice.component.scss']
})
export class CookieNoticeComponent implements OnInit {
    showCookieNotice: boolean = false;

    constructor(
        private cookieService: CookieService,
        public applicationSettingsService: ApplicationSettingsService
    ) {}

    ngOnInit(): void {
        const acceptedNotice = this.cookieService.getCookie('cookieNotice');
        this.showCookieNotice = acceptedNotice === null;
    }

    acceptCookies(): void {
        this.cookieService.setCookie('cookieNotice', 'accepted', 365);
        this.showCookieNotice = false;
    }
}
