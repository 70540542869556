<otb-cookie-notice></otb-cookie-notice>
<header [ngClass]="headerClasses" #header>
    <div
        class="header-top"
        [style.background-color]="getHeaderColor(header1Color)"
        [style.border-color]="getHeaderColor(header1Color)"
        #headerTop
    >
        <otb-error-flash-message></otb-error-flash-message>
        <div class="container">
            <div class="row">
                @if (contentPages.length > 0) {
                    <div
                        class="col-12 d-flex justify-content-between flex-wrap content-page mt-2"
                        *otbShowInEnv="['hmdj', 'hzd-internal']"
                    >
                        <!-- Left-aligned links -->
                        <ul class="d-flex gap-2 justify-content-start">
                            @for (page of leftPages; track page) {
                                <li class="p-2" [ngClass]="{ left: page.alignment === 'left' }">
                                    <a
                                        [routerLink]="[page.path_name.replace('/', '')]"
                                        [attr.aria-label]="page.alternative_text"
                                    >
                                        {{ page.name }}
                                    </a>
                                </li>
                            }
                        </ul>

                        <!-- Right-aligned links -->
                        <ul class="d-flex gap-2 justify-content-end">
                            @for (page of rightPages; track page) {
                                <li class="p-2" [ngClass]="{ right: page.alignment === 'right' }">
                                    <a
                                        [routerLink]="[page.path_name.replace('/', '')]"
                                        [attr.aria-label]="page.alternative_text"
                                    >
                                        {{ page.name }}
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
                }
                <div class="col-2 logo-container" *otbShowInEnv="['default']">
                    @if (appLoaded()) {
                        @if (showUploadedLogo) {
                            <img [src]="rootUrl + '/logo/frontend'" alt="logo" />
                        } @else {
                            <img [src]="rootUrl + '/assets/img/default/logo.png'" alt="logo" />
                        }
                    } @else {
                        <otb-skeleton-loader-logo></otb-skeleton-loader-logo>
                    }
                </div>

                <div class="col-4 logo-container d-none d-lg-block" *otbShowInEnv="['sh']">
                    @if (appLoaded()) {
                        @if (showUploadedLogo) {
                            <img [src]="rootUrl + '/logo/frontend'" alt="logo" />
                        } @else {
                            <img [src]="rootUrl + '/assets/img/sh/logo.png'" alt="logo" />
                        }
                    } @else {
                        <otb-skeleton-loader-logo></otb-skeleton-loader-logo>
                    }
                </div>

                <div class="col-4 logo-container d-block d-lg-none" *otbShowInEnv="['sh']">
                    @if (appLoaded()) {
                        @if (showUploadedLogo) {
                            <img [src]="rootUrl + '/logo/frontend'" alt="logo" />
                        } @else {
                            <img [src]="rootUrl + '/assets/img/sh/logo.png'" alt="logo" />
                        }
                    } @else {
                        <otb-skeleton-loader-logo></otb-skeleton-loader-logo>
                    }
                </div>

                <div class="col-2 offset-10" *ngIf="hasMultipleLanguages && !headless">
                    <div class="p-2 position-absolute lang-pick-container" #langPickContainer>
                        <otb-language-picker *otbShowInEnv="['mags-sop', 'justice']"></otb-language-picker>
                    </div>
                </div>
                <div class="col 12 d-flex justify-content-between align-items-center" *otbShowInEnv="['finance']">
                    <div>
                        <a
                            *ngIf="showEasyLanguagePage"
                            class="top-header-item"
                            [routerLink]="'/leichte_sprache'"
                            aria-label="Link zur Leichte Sprache"
                        >
                            <span class="contact">
                                <fa-icon class="fa" [icon]="faBookReader"></fa-icon>Leichte Sprache
                            </span>
                        </a>
                    </div>
                    <div>
                        <a
                            class="top-header-item"
                            href="https://www.finanzverwaltung.nrw.de/de/kontakt"
                            target="_blank"
                            rel="noopener"
                            aria-label="KONTAKT - Dieser Link wird in einer neuen Tab geöffnet"
                        >
                            <span class="contact"> <fa-icon class="fa" [icon]="faPaperPlane"></fa-icon>Kontakt </span>
                        </a>
                        <a
                            class="top-header-item"
                            [routerLink]="'/barrierefreiheit'"
                            aria-label="Link zur Erklärung der Barrierefreiheit"
                            >Barrierefreiheit</a
                        >
                    </div>
                </div>

                @if (appLoaded()) {
                    <otb-bb-jm-header *otbShowInEnv="['bb-jm']"></otb-bb-jm-header>
                }
            </div>
        </div>
    </div>
    <div
        class="head"
        *otbShowInEnv="['justice', 'default', 'sh', 'education-check', 'finance', 'hmdj', 'bb-jm']"
        [style.background-color]="getHeaderColor(header2Color)"
        [style.border-color]="getHeaderColor(header2Color)"
    >
        <div class="container">
            <div class="row">
                <ng-container *otbShowInEnv="['bb-jm']">
                    <div class="col-12 col-sm-4 order-0 order-sm-0 logo-container logo-mobile--sm">
                        @if (appLoaded()) {
                            <otb-bb-jm-logo></otb-bb-jm-logo>
                        } @else {
                            <otb-skeleton-loader-logo></otb-skeleton-loader-logo>
                        }
                    </div>
                </ng-container>

                <div class="col-12 col-sm-8 order-1">
                    @if (!appLoaded() && !errorLoadingApplicationSettings()) {
                        <otb-skeleton-loader-header></otb-skeleton-loader-header>
                    } @else {
                        <a
                            [attr.aria-label]="
                                'dynamische_frontend_texte.header.attribute_text.page_reload' | translate
                            "
                            (click)="clearStorage()"
                            [routerLink]="getRootUrl()"
                            class="headline-anchor"
                        >
                            <h1
                                class="headline"
                                [innerHTML]="
                                    'dynamische_frontend_texte.header.html_content.ueberschrift' | translate | safeStyle
                                "
                            ></h1>
                            <p
                                class="subheader"
                                [innerHTML]="
                                    'dynamische_frontend_texte.header.html_content.sub_ueberschrift'
                                        | translate
                                        | safeStyle
                                "
                                *otbShowInEnv="['education-check', 'finance', 'justice', 'hmdj', 'bb-jm']"
                            ></p>
                        </a>
                    }
                </div>
                <ng-container *otbShowInEnv="['education-check']">
                    @if (appLoaded()) {
                        <div class="col-12 col-sm-4 order-0 order-sm-2 logo-container logo-mobile--sm">
                            @if (showUploadedLogo) {
                                <img class="logo-image" [src]="rootUrl + '/logo/frontend'" alt="Bildungsscheck Logo" />
                            } @else {
                                <img
                                    class="logo-image"
                                    [src]="rootUrl + '/assets/img/education-check/bildungsscheck_logo.jpg'"
                                    alt="Bildungsscheck Logo"
                                />
                            }
                        </div>
                    } @else {
                        <otb-skeleton-loader-logo></otb-skeleton-loader-logo>
                    }
                </ng-container>
                <div
                    class="col-12 col-sm-4 order-0 order-sm-2 logo-container logo-mobile--sm"
                    *otbShowInEnv="['finance']"
                >
                    @if (appLoaded()) {
                        @if (showUploadedLogo) {
                            <img class="logo-image" [src]="rootUrl + '/logo/frontend'" alt="Finanzministerium Logo" />
                        } @else {
                            <img
                                class="logo-image"
                                [src]="rootUrl + '/assets/img/finance/headline_logo.svg'"
                                alt="Finanzministerium Logo"
                            />
                        }
                    } @else {
                        <otb-skeleton-loader-logo></otb-skeleton-loader-logo>
                    }
                </div>
            </div>
            <div class="row position-relative" *otbShowInEnv="['justice', 'default', 'hmdj']">
                @if (appLoaded() && !errorLoadingApplicationSettings()) {
                    <div
                        class="tel-box"
                        [style.background-color]="getHeaderColor(header1Color)"
                        [style.border-color]="getHeaderColor(header1Color)"
                    >
                        <p
                            [innerHTML]="
                                'dynamische_frontend_texte.header.html_content.kontakt' | translate | safeStyle
                            "
                        ></p>
                        <fa-icon
                            [icon]="phoneIcon"
                            [styles]="{
                                stroke: 'white',
                                color: 'white',
                                height: '12px',
                                'margin-right': '12px'
                            }"
                        >
                        </fa-icon>
                        <a
                            [attr.href]="
                                'tel:' +
                                ('dynamische_frontend_texte.header.attribute_text.tel-international' | translate)
                            "
                            [attr.title]="'dynamische_frontend_texte.header.attribute_text.tel-visible' | translate"
                            [innerHTML]="
                                'dynamische_frontend_texte.header.html_content.tel-visible' | translate | safeStyle
                            "
                        >
                        </a>
                    </div>
                }
            </div>
        </div>
    </div>

    <div
        class="head"
        *otbShowInEnv="['hzd-internal']"
        [style.background-color]="getHeaderColor(header2Color)"
        [style.border-color]="getHeaderColor(header2Color)"
    >
        <div class="container">
            <div class="row">
                <div class="col-12 order-0 order-sm-0 logo-container logo-mobile--sm" *otbShowInEnv="['hzd-internal']">
                    <div class="d-inline-block">
                        @if (appLoaded()) {
                            @if (showUploadedLogo) {
                                <img class="logo-image" [src]="rootUrl + '/logo/frontend'" alt="Hessen Logo" />
                            } @else {
                                <img
                                    class="logo-image"
                                    [src]="rootUrl + '/assets/img/hzd-internal/logo.hessen.png'"
                                    alt="Hessen Logo"
                                />
                            }
                        } @else {
                            <otb-skeleton-loader-logo></otb-skeleton-loader-logo>
                        }
                    </div>
                    <div class="d-inline-block header-text-container">
                        <a
                            aria-label="'dynamische_frontend_texte.header.attribute_text.page_reload' | translate"
                            (click)="clearStorage()"
                            [routerLink]="getRootUrl()"
                            class="headline-anchor"
                        >
                            <span
                                class="headline"
                                [innerHTML]="'dynamische_frontend_texte.header.html_content.ueberschrift' | translate"
                            ></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
